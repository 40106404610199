
import { msalInstance } from '../index';
import { protectedResources } from '../config/msalConfig';

const execute = async (method, endpoint, data = null) => {
    //console.log('Executing fetch with MSAL', method, endpoint, data);
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error('No active account found');
    }

    var authObject = {
        scopes: protectedResources.api.scopes.read,
        account: msalInstance.getActiveAccount(),
        redirectUri: '/',
    };

    const result = await msalInstance.acquireTokenSilent({
        ...authObject,
        account: account,
    });

    try {
        let response = null;

        const headers = new Headers();
        const bearer = `Bearer ${result.accessToken}`;
        headers.append("Authorization", bearer);

        if (data) headers.append('Content-Type', 'application/json');

        let options = {
            method: method,
            headers: headers,
            body: data ? JSON.stringify(data) : null,
        };

        response = await (await fetch(endpoint, options)).json();
        //console.log(response);
        return response;
    } catch (e) {
        throw e;
    }

};

export default execute;