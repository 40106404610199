import styled from "styled-components";
import { FaFilePen, FaTrash, FaEnvelope, FaPlus } from 'react-icons/fa6';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';
import DeleteConfirmationModal from "components/shared/DeleteConfirmationModal";
import { Badge, Button, Space, Table, Input } from "antd";
import qs from 'qs';
import { DataEditor } from "./DataEditor";
import OrgModuleData from "components/shared/OrganizationModules/OrgModuleData";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';



function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');  // Adjust 'en-US' as needed for your locale
}

function OrgCustData({ organization, searchResults }) {    
    const [isDataEditorOpen, setIsDataEditorOpen] = useState(false);        
    const [editableDataItem, setEditableDataItem] = useState({});

    const [currentRecord, setCurrentRecord] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const { orgId } = useParams();
    const [customers, setCustomers] = useState(searchResults || []);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 10,
        },
    });

    const openEditorToAdd = () => {
        console.log("openEditorToAdd");
        setEditableDataItem({Org_ID: orgId});
        setIsDataEditorOpen(true);
    };

    const openEditorToEdit = (record) => {
        console.log("edit", record);
        setEditableDataItem(record);
        setIsDataEditorOpen(true);
    };

    //searchFilter
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
                marginBottom: 8,
                display: 'block',
            }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({
                    closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
                }}
            >
                Filter
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                close();
                }}
            >
                close
            </Button>
            </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1677ff' : undefined,
            }}
        />
        ),
        onFilter: (value, record) =>
        record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        filterDropdownProps: {
        onOpenChange(open) {
            if (open) {
            setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

  //endSearchFilter

    const columns = [
        {
            title: 'Created On',
            dataIndex: 'CreatedOn',
            key: 'CreatedOn',
            sorter: true,
            render: (text) => formatDate(text),
        },
        {
            title: 'Customer Name',
            dataIndex: 'CustomerName',
            key: 'CustomerName',
            sorter: true,
            ...getColumnSearchProps('CustomerName'),
        },
        {
            title: 'Domain Name',
            dataIndex: 'Domain',
            key: 'Domain',
            sorter: true,
            ...getColumnSearchProps('Domain'),
        },
        {
            title: 'Address',
            dataIndex: 'Address',
            key: 'Address',
            sorter: true,
            ...getColumnSearchProps('Address'),

        },
        {
            title: 'Workspace Name',
            dataIndex: 'Workspace_Name',
            key: 'Workspace_Name',
            sorter: true,
            ...getColumnSearchProps('Workspace_Name'),

        },
        {
            title: 'Database Name',
            dataIndex: 'DatabaseName',
            key: 'DatabaseName',
            sorter: true,
            ...getColumnSearchProps('DatabaseName'),

        },
        {
            title: 'Cust Admin',
            dataIndex: 'CustEmail',
            key: 'CustEmail',
            sorter: true,
            ...getColumnSearchProps('CustEmail'),

        },
        {
            title: <Button color="default" variant="text" onClick={ openEditorToAdd } icon={<FaPlus  style={{ color: '#ffad1c' }} />} />,
            key: 'actions',
            className: 'w-130',
            render: (text, record) => (
                <>
                    <Button  color="default" variant="text"  title="Resend the invitation" onClick={(e) => {
                        e.stopPropagation();
                        handleSendInvitation(record);
                    }} icon={<FaEnvelope style={{ color: '#ffad1c' }} />} />
                                            
                    <Button   color="default" variant="text" onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        openEditorToEdit(record);
                    }} icon={<FaFilePen  style={{ color: '#ffad1c' }} />} />
                                        
                    <Button   color="default" variant="text" onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        handleDelete(record);
                    }} icon={<FaTrash style={{ color: '#ffad1c' }} />} />
                </>
                
            ),
        },
    ];

    const handleDataEditFinished = (newData) => {
        console.log("handleDataEditFinished", newData, editableDataItem);
        setIsDataEditorOpen(false);
        fetchData();
    };

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        setIsDataEditorOpen(false);
    };


    const getTableParams = (params) => ({
        pS: params.pagination?.pageSize,
        pN: params.pagination?.current,
        sF: params.sortField,
        sO: params.sortOrder,
        f: params.filters
        // ...params,
    });

    const handleSendInvitation = async (record) => {
        try {
        var response = await execute('POST', `${protectedResources.api.baseEndPoint}/customers/sendSignupInvitationToCustomer`, {
            Customer_ID: record.Customer_ID
        });
        if(response.success){
          alert('Invitation sent successfully');
        }else{
          throw new Error(response.message || 'Failed to send invitation');
        }
        }catch(error){
          alert('Failed to send invitation: ' + error.message);
        }
    };

    const handleDelete = (record) => {
        setCurrentRecord({ ...record });
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await execute('DELETE', `${protectedResources.api.baseEndPoint}/customers`, {
                    custId: currentRecord.Customer_ID
                });
            if (response.success) {
                alert('User deleted successfully.');
                await fetchData(); // Refetch data after deletion
            } else {
                setError(response.message || 'Failed to delete user');
            }
        } catch (error) {
            alert('Failed to delete user: ' + error.message);
        }
        setDeleteModalOpen(false);
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            console.log(getTableParams(tableParams))
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/organizations/${orgId}/customers?${qs.stringify(getTableParams(tableParams))}`);
            if (response.success && response.data.length > 0) {
                console.log("customerData///////////")
                console.log(response.data)
                setCustomers(response.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                      ...tableParams.pagination,
                      //total: 200,
                      // 200 is mock data, you should read it from server
                        total: response.totalRecords,
                    },
                  });
                setError('');
            } else {
                setCustomers([]);
                setError(response.message || 'No customers found for the specified organization');
            }
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        if(orgId){
            fetchData();
        }
    }, [orgId, 
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        // searchText
        tableParams?.filters,
    ]);

    useEffect(() => {
        fetchData();
    }, [orgId]);

    useEffect(() => {
        if (searchResults) {
            setCustomers(searchResults);
        }
    }, [searchResults]);

    const handleTableChange = (pagination, filters, sorter) => {
        console.log("filters change")
        console.log(filters)
        console.log('handleTableChange', pagination, filters, sorter);

        // Filter out null or empty values
        const cleanedFilters = Object.fromEntries(
            Object.entries(filters).filter(([key, value]) => value && value.length > 0)
        );

        console.log(cleanedFilters)

        setTableParams({
          pagination,
          filters: cleanedFilters,
          sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
          sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setCustomers([]);
        }
    };

    const expandedRowRender = (record) => (
        <OrgModuleData customer={record} />
    );

    return (
        <TableContainer>
            <Table
                columns={columns}
                rowKey={record => record.Customer_ID}
                dataSource={customers}
                pagination={tableParams.pagination}
                loading={loading}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                }}
                onChange={handleTableChange } />      
            { isDataEditorOpen ? 
                <DataEditor 
                    isOpen={isDataEditorOpen} 
                    dataItem={editableDataItem} 
                    onDataEditorFinished={handleDataEditFinished} 
                    onDataEditorCancelled={handleDataEditCancelled} /> 
                    : null }
            {isDeleteModalOpen && <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}
        </TableContainer>
    );
}

const TableContainer = styled.div`
    border-radius: 0px;
    // max-height: 350px; /* Set maximum height as desired */
    // overflow-y: auto; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;



export default OrgCustData;