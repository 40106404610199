import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { protectedResources } from 'config/msalConfig';
import execute from "hooks/execute";

function ErrorLogsDetail({ userDetails }) {

    const { fileName } = useParams();

    const [logs, setLogs] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")

    const fetchErrorLog = async (values) => {
        console.log("fetching Logs")

        try {
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/logs/error/${fileName}`);
            console.log(response)
            if (response) {
                if (response) {
                    console.log(response)

                    setLogs(response.data)
                    setLoading(false);
                    // setSearchResults(response.data.customers); // Assuming customers are part of the response
                } else {
                    setError(response.message || 'No error files');
                    setLoading(false);
                }
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        
        console.log("fileName//////")
        console.log(fileName)
        fetchErrorLog()
    }, [execute, fileName]);





    return (

        <div style={{ padding: '1rem', fontFamily: 'Arial, sans-serif' }}>
            <h2>Error Logs</h2>
            <div style={{ maxHeight: '500px', overflowY: 'scroll', backgroundColor: '#f8f8f8', border: '1px solid #ddd', borderRadius: '5px', padding: '1rem' }}>
                {logs.map((log, index) => (
                    <div key={index} style={{ marginBottom: '1rem' }}>
                        <strong style={{ color: log.level === 'error' ? 'red' : 'orange' }}>
                            [{log.timestamp}] {log.level?.toUpperCase()}:
                        </strong>
                        <p style={{ margin: '0.5rem 0', fontStyle: 'italic' }}>{log.message}</p>
                        {log.stackTrace && (
                            <pre
                                style={{
                                    background: '#282c34',
                                    color: '#fff',
                                    padding: '0.5rem',
                                    borderRadius: '5px',
                                    overflowX: 'auto',
                                }}
                            >
                                {log.stackTrace}
                            </pre>
                        )}
                    </div>
                ))}
            </div>
        </div>

    );
}

export default ErrorLogsDetail;
