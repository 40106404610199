import React, { useEffect, useState } from 'react';
import OrgCustPageHeader from "./OrgCustPageHeader";
import OrgCustPageSearch from "./OrgCustPageSearch";
import OrgCustData from './OrgCustData';
import { useParams } from 'react-router-dom';
import { protectedResources } from 'config/msalConfig';
import execute from "hooks/execute";

function OrgCustomersManager({ userDetails }) {
    const { orgId } = useParams();
    const [organization, setOrganization] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await execute('GET', `${protectedResources.api.baseEndPoint}/organizations/${orgId}`);
                if(response){
                    if (response.success) {  
                        setOrganization(response.data);
                        setLoading(false);
                        // setSearchResults(response.data.customers); // Assuming customers are part of the response
                    }else{
                        setError(response.message || 'No organization found for the specified name');
                        setLoading(false);
                    }
                }
            } catch (err) {
                setError('Failed to fetch data: ' + err.message);
                setLoading(false);
            }
        };
        if(orgId > 0){        
            fetchData();
        }
    }, [orgId]);





    return (
        (organization) ?
        <div 
            style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "20px 0",
            }}
            >
            <OrgCustPageHeader organization={organization} />
            {/* <OrgCustPageSearch setSearchResults={setSearchResults} /> */}
            <OrgCustData organization={organization}  searchResults={searchResults} />
            </div>
        : <div>{loading ? 'Loading...' : error}</div>
    );
}

export default OrgCustomersManager;
