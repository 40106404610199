import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { protectedResources } from 'config/msalConfig';
import execute from "hooks/execute";

function ErrorLogs({ userDetails }) {

    const [errorFiles, setErrorFiles] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")

    const fetchLogs = async (values) => {
        console.log("fetching Logs")

        try {
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/logs/error`);
            if (response) {
                if (response) {
                    console.log(response)

                    setErrorFiles(response)
                    setLoading(false);
                    // setSearchResults(response.data.customers); // Assuming customers are part of the response
                } else {
                    setError(response.message || 'No error files');
                    setLoading(false);
                }
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs()
    }, [execute]);





    return (

        <div
            style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "20px 0",
            }}
        >
            {errorFiles.map((file) => {
                return <Link to={'/error_logs/' + file.split(".")[0]}>
                    <h2>{file.split(".")[0]}</h2>
                </Link>
            })}
        </div>

    );
}

export default ErrorLogs;
